import { createStylesParam, createStylesParams, StyleParamType, wixColorParam, wixFontParam } from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { Alignment } from '../../utils/alignment';

export enum FormFieldBorderStyle {
  None = 0,
  All = 1,
  Bottom = 2,
}

type PageCustomizationStylesParams = {
  // Layout
  pageHorizontalPadding: StyleParamType.Number;
  pageVerticalPadding: StyleParamType.Number;
  pageColumnSpacing: StyleParamType.Number;
  planFormRowSpacing: StyleParamType.Number;
  planFormColumnSpacing: StyleParamType.Number;
  planCardImageHeight: StyleParamType.Number;
  planCardAlignment: StyleParamType.Number;

  // Display
  showPageTitle: StyleParamType.Boolean;
  showPlanCard: StyleParamType.Boolean;
  showPlanCardImage: StyleParamType.Boolean;
  showPlanCardName: StyleParamType.Boolean;
  showPlanCardDescription: StyleParamType.Boolean;
  showPlanCardPerks: StyleParamType.Boolean;

  // Background & Border
  pageBackgroundColor: StyleParamType.Color;
  planFormBackgroundColor: StyleParamType.Color;
  planFormBorderColor: StyleParamType.Color;
  planFormBorderRadius: StyleParamType.Number;
  planFormBorderWidth: StyleParamType.Number;
  planFormEnableShadow: StyleParamType.Boolean;
  planFormShadowAngle: StyleParamType.Number;
  planFormShadowDistance: StyleParamType.Number;
  planFormShadowBlur: StyleParamType.Number;
  planFormShadowColor: StyleParamType.Color;
  planCardBackgroundColor: StyleParamType.Color;
  planCardBorderColor: StyleParamType.Color;
  planCardBorderWidth: StyleParamType.Number;
  planCardBorderRadius: StyleParamType.Number;
  planCardEnableShadow: StyleParamType.Boolean;
  planCardShadowAngle: StyleParamType.Number;
  planCardShadowDistance: StyleParamType.Number;
  planCardShadowBlur: StyleParamType.Number;
  planCardShadowColor: StyleParamType.Color;

  // Typography
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  planFormFieldLabelColor: StyleParamType.Color;
  planFormFieldLabelFont: StyleParamType.Font;
  planFormFieldInputColor: StyleParamType.Color;
  planFormFieldInputFont: StyleParamType.Font;
  planFormFieldDescriptionColor: StyleParamType.Color;
  planFormFieldDescriptionFont: StyleParamType.Font;
  planFormFieldPlaceholderColor: StyleParamType.Color;
  planFormFieldLinkColor: StyleParamType.Color;
  planFormHeader1Font: StyleParamType.Font;
  planFormHeader1Color: StyleParamType.Color;
  planFormHeader2Font: StyleParamType.Font;
  planFormHeader2Color: StyleParamType.Color;
  planFormParagraphFont: StyleParamType.Font;
  planFormParagraphColor: StyleParamType.Color;
  planFormDisclaimerFont: StyleParamType.Font;
  planFormDisclaimerColor: StyleParamType.Color;
  planCardNameFont: StyleParamType.Font;
  planCardNameColor: StyleParamType.Color;
  planCardPriceFont: StyleParamType.Font;
  planCardPriceColor: StyleParamType.Color;
  planCardPaymentFrequencyFont: StyleParamType.Font;
  planCardPaymentFrequencyColor: StyleParamType.Color;
  planCardSetupFeeFont: StyleParamType.Font;
  planCardSetupFeeColor: StyleParamType.Color;
  planCardDescriptionFont: StyleParamType.Font;
  planCardDescriptionColor: StyleParamType.Color;
  planCardDurationFont: StyleParamType.Font;
  planCardDurationColor: StyleParamType.Color;

  // Form fields
  planFormFieldBorderStyle: StyleParamType.Number;
  planFormFieldBackgroundColor: StyleParamType.Color;
  planFormFieldBackgroundColorHover: StyleParamType.Color;
  planFormFieldBorderRadius: StyleParamType.Number;
  planFormFieldBorderColor: StyleParamType.Color;
  planFormFieldBorderColorHover: StyleParamType.Color;
  planFormFieldBorderWidth: StyleParamType.Number;
  planFormFieldCheckedColor: StyleParamType.Color;
  planFormFieldErrorColor: StyleParamType.Color;
};

const componentStyles = createStylesParams<PageCustomizationStylesParams>({
  // Layout
  pageHorizontalPadding: createStylesParam('page-horizontal-padding', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  pageVerticalPadding: createStylesParam('page-vertical-padding', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  pageColumnSpacing: createStylesParam('page-column-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  }),
  planFormRowSpacing: createStylesParam('plan-form-row-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  }),
  planFormColumnSpacing: createStylesParam('plan-form-column-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  }),
  planCardImageHeight: createStylesParam('plan-card-image-height', {
    type: StyleParamType.Number,
    getDefaultValue: () => 160,
  }),
  planCardAlignment: createStylesParam('plan-card-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.CENTER,
  }),

  // Display
  showPageTitle: createStylesParam('show-page-title', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCard: createStylesParam('show-plan-card', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardImage: createStylesParam('show-plan-card-image', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardName: createStylesParam('show-plan-card-name', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardDescription: createStylesParam('show-plan-card-description', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardPerks: createStylesParam('show-plan-card-perks', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),

  // Background & Border
  pageBackgroundColor: createStylesParam('page-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 0),
  }),
  planFormBackgroundColor: createStylesParam('plan-form-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 0),
  }),
  planFormBorderColor: createStylesParam('plan-form-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planFormBorderRadius: createStylesParam('plan-form-border-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormBorderWidth: createStylesParam('plan-form-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormEnableShadow: createStylesParam('plan-form-shadow', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  planFormShadowAngle: createStylesParam('plan-form-shadow-angle', {
    type: StyleParamType.Number,
    getDefaultValue: () => 315,
  }),
  planFormShadowDistance: createStylesParam('plan-form-shadow-distance', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormShadowBlur: createStylesParam('plan-form-shadow-blur', {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  }),
  planFormShadowColor: createStylesParam('plan-form-shadow-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planCardBackgroundColor: createStylesParam('plan-card-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 0),
  }),
  planCardBorderColor: createStylesParam('plan-card-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planCardBorderWidth: createStylesParam('plan-card-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  planCardBorderRadius: createStylesParam('plan-card-border-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planCardEnableShadow: createStylesParam('plan-card-apply-shadow', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  planCardShadowAngle: createStylesParam('plan-card-shadow-angle', {
    type: StyleParamType.Number,
    getDefaultValue: () => 315,
  }),
  planCardShadowDistance: createStylesParam('plan-card-shadow-distance', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planCardShadowBlur: createStylesParam('plan-card-shadow-blur', {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  }),
  planCardShadowColor: createStylesParam('plan-card-shadow-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),

  // Typography
  pageTitleFont: createStylesParam('page-title-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', { htmlTag: 'h1' }),
  }),
  pageTitleColor: createStylesParam('page-title-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-title', 1),
  }),
  planFormFieldLabelColor: createStylesParam('plan-form-field-label-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planFormFieldLabelFont: createStylesParam('plan-form-field-label-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  }),
  planFormFieldInputColor: createStylesParam('plan-form-field-input-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planFormFieldInputFont: createStylesParam('plan-form-field-input-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  }),
  planFormFieldDescriptionColor: createStylesParam('plan-form-field-description-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planFormFieldDescriptionFont: createStylesParam('plan-form-field-description-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  }),
  planFormFieldPlaceholderColor: createStylesParam('plan-form-field-placeholder-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-secondary', 1),
  }),
  planFormFieldLinkColor: createStylesParam('plan-form-field-link-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-action', 1),
  }),
  planFormHeader1Font: createStylesParam('plan-form-header-1-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-L'),
  }),
  planFormHeader1Color: createStylesParam('plan-form-header-1-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planFormHeader2Font: createStylesParam('plan-form-header-2-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M'),
  }),
  planFormHeader2Color: createStylesParam('plan-form-header-2-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planFormParagraphFont: createStylesParam('plan-form-paragraph-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  }),
  planFormParagraphColor: createStylesParam('plan-form-paragraph-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planFormDisclaimerFont: createStylesParam('plan-form-disclaimer-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S'),
  }),
  planFormDisclaimerColor: createStylesParam('plan-form-disclaimer-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-secondary', 1),
  }),
  planCardNameFont: createStylesParam('plan-card-name-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-L'),
  }),
  planCardNameColor: createStylesParam('plan-card-name-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planCardPriceFont: createStylesParam('plan-card-price-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 48, lineHeight: 56 }),
  }),
  planCardPriceColor: createStylesParam('plan-card-price-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planCardPaymentFrequencyFont: createStylesParam('plan-card-payment-frequency-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S'),
  }),
  planCardPaymentFrequencyColor: createStylesParam('plan-card-payment-frequency-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planCardSetupFeeFont: createStylesParam('plan-card-setup-fee-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S'),
  }),
  planCardSetupFeeColor: createStylesParam('plan-card-setup-fee-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planCardDescriptionFont: createStylesParam('plan-card-description-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S'),
  }),
  planCardDescriptionColor: createStylesParam('plan-card-description-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary', 1),
  }),
  planCardDurationFont: createStylesParam('plan-card-duration-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S', { size: 12 }),
  }),
  planCardDurationColor: createStylesParam('plan-card-duration-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-secondary', 1),
  }),

  // Form fields
  planFormFieldBorderStyle: createStylesParam('plan-form-input-border-style', {
    type: StyleParamType.Number,
    getDefaultValue: () => FormFieldBorderStyle.All,
  }),
  planFormFieldBackgroundColor: createStylesParam('plan-form-input-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 1),
  }),
  planFormFieldBackgroundColorHover: createStylesParam('plan-form-input-background-color-hover', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary'),
  }),
  planFormFieldBorderRadius: createStylesParam('plan-form-input-border-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormFieldBorderColor: createStylesParam('plan-form-input-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planFormFieldBorderColorHover: createStylesParam('plan-form-input-border-color-hover', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.4),
  }),
  planFormFieldBorderWidth: createStylesParam('plan-form-input-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  planFormFieldCheckedColor: createStylesParam('plan-form-input-checked-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-action', 1),
  }),
  planFormFieldErrorColor: createStylesParam('plan-form-input-error-color', {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      name: null,
      opacity: 1,
      value: '#DF3131',
    }),
  }),
});

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  const planCardShadowAngle = styleParams.numbers[componentStyles.planCardShadowAngle.key!] ?? 0;
  const planCardShadowDistance = styleParams.numbers[componentStyles.planCardShadowDistance.key!] ?? 0;

  const planFormShadowAngle = styleParams.numbers[componentStyles.planFormShadowAngle.key!] ?? 0;
  const planFormShadowDistance = styleParams.numbers[componentStyles.planFormShadowDistance.key!] ?? 0;

  const planCardAlignment = styleParams.numbers[componentStyles.planCardAlignment.key!] ?? Alignment.CENTER;
  return {
    'plan-card-text-align': alignmentToTextAlignValue(planCardAlignment),
    'plan-card-align-items': alignmentToAlignItemsValue(planCardAlignment),

    'plan-card-shadow-offset-x': -Math.round(Math.sin((planCardShadowAngle * Math.PI) / 180) * planCardShadowDistance),
    'plan-card-shadow-offset-y': Math.round(Math.cos((planCardShadowAngle * Math.PI) / 180) * planCardShadowDistance),

    'plan-form-shadow-offset-x': -Math.round(Math.sin((planFormShadowAngle * Math.PI) / 180) * planFormShadowDistance),
    'plan-form-shadow-offset-y': Math.round(Math.cos((planFormShadowAngle * Math.PI) / 180) * planFormShadowDistance),
  };
};

export default componentStyles;

function alignmentToTextAlignValue(alignment: Alignment): 'left' | 'center' | 'right' {
  switch (alignment) {
    case Alignment.LEFT:
      return 'left';
    case Alignment.CENTER:
      return 'center';
    case Alignment.RIGHT:
      return 'right';
  }
}

function alignmentToAlignItemsValue(alignment: Alignment): 'start' | 'center' | 'end' {
  switch (alignment) {
    case Alignment.LEFT:
      return 'start';
    case Alignment.CENTER:
      return 'center';
    case Alignment.RIGHT:
      return 'end';
  }
}
